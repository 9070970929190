import React, { useEffect, useState } from "react";
import "./styleV2.scss";

import RevealAnimation from "../RevealAnimation";
import LeftIcon from "../../assets/Images/PodModel/we-handle-operation/slide/left.svg";
import RightIcon from "../../assets/Images/PodModel/we-handle-operation/slide/right.svg";
import CheckIcon from "../../assets/Images/India/location/check.svg";

function SliderSectionV2({
  listObjectData = [],
  wrapClassName = "",
  hideController = false,
  imageContainerClass = "",
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [stop, setStop] = useState(false);

  useEffect(() => {
    if (!stop) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) =>
          prevSlide === listObjectData.length - 1 ? 0 : prevSlide + 1
        );
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [hideController, listObjectData.length, stop]);

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === listObjectData.length - 1 ? 0 : prevSlide + 1
    );
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? listObjectData.length - 1 : prevSlide - 1
    );
  };

  const ListItems = ({ listData }) => {
    return (
      <>
        {listData.length > 0 && (
          <div className="description-list">
            {listData.map((description, idx) => (
              <RevealAnimation className="description-list-item" key={idx}>
                <div className="check-icon">
                  <img src={CheckIcon} alt="" />
                </div>
                <div className="description">{description}</div>
              </RevealAnimation>
            ))}
          </div>
        )}
      </>
    );
  };
  return (
    <div className={`sliderSectionV2Wrap ${wrapClassName}`}>
      <div className="sliderContainer body-container">
        {listObjectData?.map((slider, index) => (
          <div
            key={index}
            className={`sliderInnerWrap ${
              index === currentSlide ? "active" : ""
            }`}
          >
            <RevealAnimation className="slider-title">
              {slider.title}
            </RevealAnimation>

            <ListItems listData={slider?.descriptionList} />
            <RevealAnimation className="img-wrap">
              <img src={slider.imageUrl} alt="" />
            </RevealAnimation>
            <ListItems listData={slider?.descriptionList2} />
            <div className="content-wrap">
              {slider?.listTitle && (
                <RevealAnimation className="slider-title">
                  {slider?.listTitle}
                </RevealAnimation>
              )}
              <ListItems listData={slider?.list} />
            </div>
            {slider.source && (
              <RevealAnimation component={"p"} className="slider-source">
                {slider.source}
              </RevealAnimation>
            )}
          </div>
        ))}
        <div
          className={`controller-wrap ${hideController ? "hidden-track" : ""}`}
        >
          {!hideController && (
            <div className="track_layout">
              {listObjectData.map((_, inKey) => (
                <div
                  key={inKey}
                  className={`track ${inKey === currentSlide ? "active" : ""}`}
                  onClick={() => {
                    setCurrentSlide(inKey);
                  }}
                />
              ))}
              <div
                className={`track active ${stop ? "pause" : "play"}`}
                onClick={() => {
                  setStop(!stop);
                  if (stop) {
                    setCurrentSlide((prevSlide) =>
                      prevSlide === listObjectData.length - 1
                        ? 0
                        : prevSlide + 1
                    );
                  }
                }}
              >
                {stop ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path fill="currentColor" d="M8 5.14v14l11-7z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M14 19V5h4v14zm-8 0V5h4v14z"
                    ></path>
                  </svg>
                )}
              </div>
            </div>
          )}
          <div className="controller_nav">
            <div className="nav prev_nav" onClick={handlePrev}>
              <img className="" src={LeftIcon} alt="Previous" />
            </div>
            <div className="nav next_nav" onClick={handleNext}>
              <img className="" src={RightIcon} alt="Next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderSectionV2;
