import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/layout";
import HeroSectionOverlay from "../components/HeroSectionOverlay";
import BannerImg from "../assets/Images/India/banner/banner.png";

import Talent_img from "../assets/Images/India/icons/talent.png";
import Cost_img from "../assets/Images/India/icons/cost.png";
import Innovation_img from "../assets/Images/India/icons/innovation.png";
import Transform_img from "../assets/Images/India/icons/transform.png";
import Cultural_img from "../assets/Images/India/icons/culture.png";

import { Grid } from "@material-ui/core";
import IconCardSecondary from "../components/IconCard/secondary";
import TitleListImgSection from "../components/TitleListImgSection";
import SteveJobs_Img from "../assets/Images/India/leaders/steve-jobs.png";
import SteveJobs_2_Img from "../assets/Images/India/leaders/steve-jobs-2.png";
import Matt_Img from "../assets/Images/India/leaders/matt.png";
import Einstein_Img from "../assets/Images/India/leaders/einstein.png";
import BillGates_Img from "../assets/Images/India/leaders/billgates.png";

import Key_Indian_Tech_Cities from "../assets/Images/India/banner/Key_Indian_Tech_Cities.png";
import Silicon_Valley_of_India from "../assets/Images/India/banner/Silicon_Valley_of_India.png";
import Hyderabad_The_Cyber_City from "../assets/Images/India/banner/Hyderabad_The_Cyber_City.png";
import Pune_The_IT_Research_Hub from "../assets/Images/India/banner/Pune_The_IT_Research_Hub.png";
import Chennai_The_SaaS_Capital from "../assets/Images/India/banner/Chennai_The_SaaS_Capital.png";

import Infosys_img from "../assets/Images/India/icons/infosys.svg";
import Wipro_img from "../assets/Images/India/icons/wipro.svg";
import Google_img from "../assets/Images/India/icons/google.svg";
import Microsoft_img from "../assets/Images/India/icons/microsoft.svg";

import Facebook_img from "../assets/Images/India/icons/facebook.svg";
import Apple_img from "../assets/Images/India/icons/apple.svg";
import Amazon_img from "../assets/Images/India/icons/amazon.svg";
import Salesforce_img from "../assets/Images/India/icons/salesforce.svg";

import Symantec_img from "../assets/Images/India/icons/symantec.svg";
import Ibm_img from "../assets/Images/India/icons/ibm.svg";
import Tcs_img from "../assets/Images/India/icons/tcs.svg";

import Zoho_img from "../assets/Images/India/icons/zoho.svg";
import Freshworks_img from "../assets/Images/India/icons/freshworks.svg";
import Adobe_img from "../assets/Images/India/icons/adobe.svg";

import CTAImg from "../assets/Images/India/icons/cta.png";

import RevealAnimation from "../components/RevealAnimation";
import CtaCardSection from "../components/CTASection/ctaCard2";
import Box from "../templates/Box";
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import DataService from "../services/api/data_services";
import Megaview from "../templates/MegaView";
import Button from "../components/Button";
import RightArrow from "../assets/Icons/go.svg";
import LocationTab from "../components/LocationTab";

import ReactPlayer from "react-player";

import VideoThumb from "../assets/Images/Clutch/video-thumbnail.jpg";

import SliderSectionV2 from "../components/Slider/sliderV2";
import Graduates_Img from "../assets/Images/India/slider/graduates.png";
import Companies_Img from "../assets/Images/India/slider/companies.png";
import Outsourcing_Img from "../assets/Images/India/slider/outsourcing.png";
import Tech_Img from "../assets/Images/India/slider/tech.png";
import University_Img from "../assets/Images/India/slider/university.png";
import Research_Img from "../assets/Images/India/slider/research.png";
import Startups_Img from "../assets/Images/India/slider/startup.png";
import Workforce_Img from "../assets/Images/India/slider/workforce.png";
import Language_Img from "../assets/Images/India/slider/language.png";
import Government_Img from "../assets/Images/India/slider/government.png";

function IndiaPage() {
  const [megaviewData, setMegaViewData] = useState([]);

  useEffect(() => {
    DataService.getData(
      "/admin/api/post?limit=1&page=1&page_id=1&type=White Paper"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  const IconDataList = [
    {
      imgUrl: Talent_img,
      title: "Talent Availability",
      description:
        "Tap into a pool of brilliant minds from India’s top universities and tech institutes. Over 2 million engineers across the country specialise in cutting-edge technologies, ready to elevate your business.",
    },
    {
      imgUrl: Cost_img,
      title: "Cost Efficiency",
      description:
        "Achieve greater cost-effectiveness with Digiryte’s strategic operations across India, allowing you to optimise budgets without compromising on quality.",
    },
    {
      imgUrl: Innovation_img,
      title: "Innovation at Scale",
      description:
        "India’s ecosystem is booming with innovation, home to R&D centers of global tech giants and vibrant startups. Partner with us to be at the forefront of technological advancement.",
    },
    {
      imgUrl: Transform_img,
      title: "Transformative Technologies",
      description:
        "Setting the stage for transformative technologies like AI, Machine Learning, and more by connecting you with India’s brightest minds to fuel your projects and propel your growth.",
    },
    {
      imgUrl: Cultural_img,
      title: "Cultural Synergy",
      description:
        "Benefit from a workforce that’s not only technically proficient but also culturally aligned with global corporate standards, ensuring smooth integrations.",
    },

    { isLast: true, btnText: "Discover the Possibilities", btnLink: "#" },
  ];

  const CompanyLogoList = ({ iconList = [] }) => {
    return (
      <div
        className="body-container mt-50"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid
          container
          item
          direction="row"
          spacing={6}
          lg={8}
          justifyContent="center"
        >
          {iconList.map((item, index) => (
            <Grid
              key={index}
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
              className="image-icon-wrap"
            >
              <img src={item} alt="" />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const BoxData = {
    boxBtn1Width: "230px",
    boxBtn1Height: "46px",
    boxBtn1variant: "contained",
    boxBtn1color: "primary",
    boxBtn1label: "Career",
    tip: "Career",
    tooltip: "no",
    Clm2SubTitle: "careers@digiryte.com",
    boxBtn1Title: "Join our team",
    boxBtn1SubTitle: (
      <>
        If you're a designer or a developer <br /> who loves to challenge
        conventions,
        <br /> make the ryte move!
      </>
    ),
    boxBtn2Width: "230px",
    boxBtn2Height: "46px",
    boxBtn2variant: "contained",
    boxBtn2color: "primary",
    boxBtn2label: "Call us right now",
    boxBtn2Title: "Let’s talk",
    tip1: "Call us right now",
    boxBtn2SubTitle: (
      <>
        Have you got an exciting project that <br /> you'd like to discuss?{" "}
      </>
    ),
    route1: "/contact-us",
    route2: "/contact-us",
  };

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const listObjectSlideData = [
    {
      title: "Massive Pool of Engineers and Graduates",
      descriptionList: [
        <>
          India produces over{" "}
          <span className="testimonial-client">1.5 million</span> engineers
          annually, one of the largest engineering talent pools in the world.
        </>,
      ],
      imageUrl: Graduates_Img,
      descriptionList2: [],
      listTitle: "",
      list: [
        <>
          India: <span className="testimonial-client">Over 1.5 million</span>{" "}
          graduates.
        </>,
        <>
          United Kingdom: <span className="testimonial-client">120,000</span>{" "}
          graduates.
        </>,
        <>
          United States: Approximately{" "}
          <span className="testimonial-client">237,826</span> graduates.
        </>,
        <>
          Ukraine: About <span className="testimonial-client">130,391</span>{" "}
          graduates.
        </>,
        <>
          Poland: <span className="testimonial-client">150,000</span> graduates.
        </>,
      ],
      source:
        "Source: This information is derived from the most recent data available from reliable sources including WorldAtlas and OECD reports (WorldAtlas) (Education GPS).",
    },
    {
      title: "Massive Pool of Engineers and Graduates",
      descriptionList: [
        <>
          India produces over{" "}
          <span className="testimonial-client">1.5 million</span> engineers
          annually, one of the largest engineering talent pools in the world.
        </>,
      ],
      imageUrl: Companies_Img,
      descriptionList2: [],
      listTitle:
        "List of major global companies along with their Indian-origin CEOs:",
      list: [
        <>
          <b>Google </b> - Sundar Pichai
        </>,
        <>
          <b>Microsoft </b> - Satya Nadella
        </>,
        <>
          <b>Adobe </b> - Shantanu Narayen
        </>,
        <>
          <b>IMB </b> - Arvind Krishna
        </>,
        <>
          <b>Mastercard </b> - Ajay Banga
        </>,
        <>
          <b>Palo Alto Networks </b> - Nikesh Arora
        </>,
        <>
          <b>Micron Technology </b> - Sanjay Mehrotra
        </>,
        <>
          <b>VMware </b> - Raghu Raghuram
        </>,
      ],
      source: "",
    },
    {
      title: "Leading Global Services Hub",
      descriptionList: [
        <>
          India holds a significant share of the global IT outsourcing market,
          estimated at <span className="testimonial-client">55%,</span> making
          it the world's premier outsourcing destination.
        </>,
      ],
      imageUrl: Outsourcing_Img,
      descriptionList2: [
        <>
          India holds the largest segment, comprising{" "}
          <span className="testimonial-client">55%,</span> of the market. Other
          notable segments include Eastern Europe with{" "}
          <span className="testimonial-client">20%,</span> the Philippines with{" "}
          <span className="testimonial-client">15%,</span> and Latin America
          with <span className="testimonial-client">10%.</span>
        </>,
      ],
      listTitle:
        "List of major global companies along with their Indian-origin CEOs:",
      list: [
        <>
          <b>Google </b> - Sundar Pichai
        </>,
        <>
          <b>Microsoft </b> - Satya Nadella
        </>,
        <>
          <b>Adobe </b> - Shantanu Narayen
        </>,
        <>
          <b>IMB </b> - Arvind Krishna
        </>,
        <>
          <b>Mastercard </b> - Ajay Banga
        </>,
        <>
          <b>Palo Alto Networks </b> - Nikesh Arora
        </>,
        <>
          <b>Micron Technology </b> - Sanjay Mehrotra
        </>,
        <>
          <b>VMware </b> - Raghu Raghuram
        </>,
      ],
      source:
        "Source: reports from sources like Allied Market Research and Straits Research​ (Allied Market Research)​​ (StraitsResearch)​.",
    },
    {
      title: "Rapid Adoption of Cutting-Edge Technologies",
      descriptionList: [
        <>
          Indian professionals are quick adopters of emerging technologies—AI,
          machine learning, and blockchain—and are integral in evolving these
          sectors worldwide.
        </>,
      ],
      imageUrl: Tech_Img,
      descriptionList2: [],
      listTitle: "",
      list: [],
      source:
        "Source: Insights are generally derived from industry trends observed in reports by NASSCOM and Stack Overflow’s Developer Survey.",
    },
    {
      title: "Strong Educational Foundation",
      descriptionList: [
        <>
          There are over{" "}
          <span className="testimonial-client">100 universities</span> and{" "}
          <span className="testimonial-client">1,000+</span> technical
          institutions in India that are part of a rigorous STEM education
          system, producing hundreds of thousands of skilled tech graduates each
          year.
        </>,
      ],
      imageUrl: University_Img,
      descriptionList2: [],
      listTitle: "",
      list: [
        <>
          <b>IIT Delhi </b> in New Delhi
        </>,
        <>
          <b>IIT Bombay </b> in Mumbai
        </>,
        <>
          <b>IIT Madras </b> in Chennai
        </>,
        <>
          <b>NIT Trichy </b> in Tiruchirappalli
        </>,
        <>
          <b>BITS Pilani </b> in Pilani
        </>,
      ],
      source:
        "Source: Data can be verified through the Ministry of Education or specific university websites.",
    },
    {
      title: "Research and Development (R&D) Prowess",
      descriptionList: [
        <>
          India’s R&D landscape includes over
          <span className="testimonial-client">1,200</span> R&D centers, many of
          which are owned by multinational corporations, underscoring the
          country's role as a critical hub of global innovation.
        </>,
      ],
      imageUrl: Research_Img,
      descriptionList2: [],
      listTitle: "",
      list: [],
      source:
        "Source: Company-specific R&D locations are often announced in press releases and can be found on corporate websites or in business news articles on platforms like Business Standard.",
    },
    {
      title: "Startup Ecosystem",
      descriptionList: [
        <>
          With over <span className="testimonial-client">20,000 startups,</span>{" "}
          including more than{" "}
          <span className="testimonial-client">30 unicorns,</span> India’s
          startup ecosystem is the third largest in the world. Indian startups
          raised <span className="testimonial-client">over $10 billion</span> in
          funding in the last year alone.
        </>,
      ],
      imageUrl: Startups_Img,
      descriptionList2: [],
      listTitle: "",
      list: [],
      source:
        "Source: Comprehensive details are often covered in industry reports by Inc42, a well-known startup news platform.",
    },
    {
      title: "Diversity in Tech",
      descriptionList: [
        <>
          India’s tech sector is striving towards greater diversity, with women
          constituting approximately{" "}
          <span className="testimonial-client">34%</span> of the workforce in
          software and IT services.
        </>,
      ],
      imageUrl: Workforce_Img,
      descriptionList2: [],
      listTitle: "",
      list: [],
      source:
        "Source: Workforce diversity reports by tech companies and gender studies by Catalyst provide this information.",
    },
    {
      title: "Language Proficiency",
      descriptionList: [
        <>
          English is one of the official languages in India and is widely used
          in professional settings, especially in tech, making communication
          seamless for international collaborations.
        </>,
      ],
      imageUrl: Language_Img,
      descriptionList2: [],
      listTitle: "",
      list: [],
      source:
        "Source: The EF English Proficiency Index provides an annual ranking of countries by English skills.",
    },
    {
      title: "Government Support and Digital India Initiative",
      descriptionList: [
        <>
          The Indian government's Digital India initiative aims to boost the
          digital infrastructure of the country, further enhancing India’s
          attractiveness as a tech hub.
        </>,
      ],
      imageUrl: Government_Img,
      descriptionList2: [],
      listTitle: "",
      list: [],
      source:
        "Source: The official Digital India portal provides comprehensive details on all components of the initiative.",
    },
  ];

  return (
    <Layout
      seo={{
        title: "India | Digiryte",
        ogtitle: "India | Digiryte",
        description: "Welcome to India",
      }}
    >
      <HeroSectionOverlay
        title={
          <>
            Unlock the Future with India’s <br />
            Premier Tech Talent
          </>
        }
        subtitle={
          <>
            Build Your World-Class Development <br />
            Team Across India with Digiryte
          </>
        }
        description={
          <>
            Leverage India’s top tech talent and innovative ecosystem with
            Digiryte. Partner with us to build a world-class development team
            across India’s leading tech cities. Discover the potential today!
          </>
        }
        bannerImg={BannerImg}
      />
      <div className="common-bg-light">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Leverage India's Technological Prowess <br /> to Ensure Your Digital
            Brilliance
          </RevealAnimation>
          <div className="mt-50">
            <Grid
              item
              direction="row"
              container
              spacing={5}
              alignItems="stretch"
              // justifyContent="center"
            >
              {IconDataList.map((item, index) => (
                <Grid item xs={12} sm={12} md={6} xl={4} key={index}>
                  <IconCardSecondary
                    imgUrl={item.imgUrl}
                    title={item.title}
                    description={item.description}
                    isLast={item.isLast}
                    btnText={item.btnText}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
      <div>
        <TitleListImgSection
          subTitle={
            <>
              ‘Innovation <br /> distinguishes between <br />a leader and a
              follower’
            </>
          }
          description="- Steve Jobs"
          listItems={[]}
          btnLink=""
          imageUrl={SteveJobs_Img}
          wrapClassName="row-reverse common-bg-light bg-white"
          stepCount=""
        />
      </div>
      <div className="common-bg-light">
        <TitleListImgSection
          subTitle={
            <>
              Partner with Digiryte to Build Your Next-Generation Tech Team
              Across Key Indian Tech Cities
            </>
          }
          subTitleClassName="font32"
          description="India, a thriving epicentre of technological innovation, offers a fertile ground for developing cutting-edge solutions. With Digiryte, access India’s finest tech talent pooled across multiple cities, each with its unique tech persona and specialisations."
          listItems={[]}
          btnLink=""
          imageUrl={Key_Indian_Tech_Cities}
          wrapClassName=""
          stepCount=""
        />
      </div>
      <div className=" mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="full-story-title"
            style={{ textAlign: "center", width: "100%" }}
          >
            Tech Cities Showcase
          </RevealAnimation>
        </div>
        <TitleListImgSection
          subTitle={
            <>
              Bangalore: <br />
              The Silicon Valley of <br />
              India
            </>
          }
          listItems={[
            {
              description:
                "The leader in IT exports and a beacon of innovation, Bangalore is the birthplace of some of the most groundbreaking technological advancements.",
            },
            {
              description: (
                <>
                  Over 1 million IT professionals, hosting{" "}
                  <span
                    className="testimonial-client"
                    style={{ fontWeight: 700 }}
                  >
                    35%
                  </span>{" "}
                  of India’s IT companies.
                </>
              ),
            },
          ]}
          btnLink=""
          imageUrl={Silicon_Valley_of_India}
          wrapClassName="mt-50 row-reverse"
          stepCount=""
        />
        <CompanyLogoList
          iconList={[Infosys_img, Wipro_img, Google_img, Microsoft_img]}
        />
      </div>

      <div className="common-bg-light mt-70">
        <TitleListImgSection
          subTitle={
            <>
              ‘Great things in <br />
              business are never <br />
              done by one person; <br />
              they’re done by a team <br />
              of people’
            </>
          }
          subTitleClassName=""
          description="- Steve Jobs"
          listItems={[]}
          btnLink=""
          imageUrl={SteveJobs_2_Img}
          wrapClassName=""
          stepCount=""
          imgClassName="img-absolute-bottom mix-darken"
        />
      </div>
      <div className="mt-30">
        <CtaCardSection
          title={
            <>
              Develop and expand your team <br /> in{" "}
              <span className="animationTitle">Asia's </span>Silicon Valley
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAImg}
          imgClassName="custom-cta-img"
          // className="common-o-bg"
          showBtn={true}
        />
      </div>
      <div className="mt-20 common-bg-light">
        <TitleListImgSection
          subTitle={
            <>
              Hyderabad: <br />
              The Cyber City
            </>
          }
          listItems={[
            {
              description:
                "Renowned for its massive tech campuses and a thriving ecosystem supporting startups and major IT firms alike.",
            },
            {
              description: (
                <>
                  Home to over{" "}
                  <span
                    className="testimonial-client"
                    style={{ fontWeight: 700 }}
                  >
                    400,000 tech
                  </span>{" "}
                  workers and a rapidly growing startup scene.
                </>
              ),
            },
          ]}
          btnLink=""
          imageUrl={Hyderabad_The_Cyber_City}
          wrapClassName="mt-50"
          stepCount=""
        />
        <CompanyLogoList
          iconList={[Facebook_img, Apple_img, Amazon_img, Salesforce_img]}
        />
      </div>
      <div className="">
        <TitleListImgSection
          subTitle={
            <>
              ‘Technology is best <br />
              when it brings people <br />
              together’
            </>
          }
          imgClassName="img-absolute-bottom mix-darken max-w-340"
          description="– Matt Mullenweg"
          listItems={[]}
          btnLink=""
          imageUrl={Matt_Img}
          wrapClassName="row-reverse common-bg-light bg-white"
          stepCount=""
        />
      </div>
      <div className="mt-30">
        <CtaCardSection
          title={
            <>
              Develop your team by learning <br />
              <span className="animationTitle">about Hyderabad’s</span> tech
              ecosystem
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAImg}
          imgClassName="custom-cta-img"
          className="common-o-bg"
          showBtn={true}
        />
      </div>
      <div className=" mt-70">
        <TitleListImgSection
          subTitle={
            <>
              Pune: The IT <br />
              Research Hub
            </>
          }
          listItems={[
            {
              description:
                "A focal point for IT research and development, Pune excels in software development and IT services, fueled by an academic-rich environment.",
            },
            {
              description: (
                <>
                  More than{" "}
                  <span
                    className="testimonial-client"
                    style={{ fontWeight: 700 }}
                  >
                    120 IT
                  </span>{" "}
                  and{" "}
                  <span
                    className="testimonial-client"
                    style={{ fontWeight: 700 }}
                  >
                    250,000 IT
                  </span>{" "}
                  professionals.
                </>
              ),
            },
          ]}
          btnLink=""
          imageUrl={Pune_The_IT_Research_Hub}
          wrapClassName="mt-50 row-reverse"
          stepCount=""
        />
        <CompanyLogoList
          iconList={[Symantec_img, Ibm_img, Tcs_img, Infosys_img]}
        />
      </div>
      <div className="common-bg-light mt-40">
        <TitleListImgSection
          subTitle={
            <>
              ‘The only source of <br />
              knowledge is <br />
              experience.’
            </>
          }
          imgClassName="img-absolute-bottom mix-darken max-w-340"
          description="– Albert Einstein"
          listItems={[]}
          btnLink=""
          imageUrl={Einstein_Img}
          wrapClassName=""
          stepCount=""
        />
      </div>
      <div className="mt-30">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">Pune </span> should be your IT
              backbone
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAImg}
          imgClassName="custom-cta-img"
          // className="common-o-bg"
          showBtn={true}
        />
      </div>
      <div className=" mt-20 common-bg-light">
        <TitleListImgSection
          subTitle={
            <>
              Chennai: The SaaS <br />
              Capital
            </>
          }
          listItems={[
            {
              description:
                "Chennai has emerged as a major destination for SaaS companies, offering a robust infrastructure and a deep pool of engineering talent.",
            },
            {
              description: (
                <>
                  Over{" "}
                  <span
                    className="testimonial-client"
                    style={{ fontWeight: 700 }}
                  >
                    150,000 IT professionals
                  </span>{" "}
                  with a strong presence of SaaS startups.
                </>
              ),
            },
          ]}
          btnLink=""
          imageUrl={Chennai_The_SaaS_Capital}
          wrapClassName="mt-50 "
          stepCount=""
        />
        <CompanyLogoList
          iconList={[Zoho_img, Freshworks_img, Adobe_img, Microsoft_img]}
        />
      </div>
      <div className="">
        <TitleListImgSection
          subTitle={
            <>
              ‘Software is a great <br />
              combination of artistry <br />
              and engineering.’
            </>
          }
          imgClassName="img-absolute-bottom mix-darken"
          description="– Bill Gates"
          listItems={[]}
          btnLink=""
          imageUrl={BillGates_Img}
          wrapClassName="row-reverse common-bg-light bg-white"
          stepCount=""
        />
      </div>
      <div className="mt-30">
        <CtaCardSection
          title={
            <>
              Discover the Benefits of Choosing <br />
              <span className="animationTitle">Chennai </span> for Your SAAS
              Needs
            </>
          }
          titleClassName="customTitle"
          imageUrl={CTAImg}
          imgClassName="custom-cta-img"
          className="common-o-bg"
          showBtn={true}
        />
      </div>

      <div className="mt-70">
        <LocationTab />
      </div>
      <div className="mt-70 common-bg-light">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Harnessing India’s Tech Talent
          </RevealAnimation>
          <RevealAnimation className="info-view-content">
            India’s vibrant tech landscape is powered by a robust and highly
            skilled workforce, recognised globally for it’s technical
            proficiency and innovative capabilities. Here, we explore the facts
            and figures that highlight why India is a top destination for
            sourcing tech talent.
          </RevealAnimation>
          <RevealAnimation animation="zoom-in" className="video-player-wrap">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=9AlqpXou_zQ"
              light={VideoThumb}
              width={"100%"}
              height={620}
              style={{}}
              pip={false}
              controls={false}
            />
          </RevealAnimation>
        </div>
      </div>
      <div className="mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Key Highlights of Indian Tech Talent
          </RevealAnimation>
        </div>
        <SliderSectionV2
          listObjectData={listObjectSlideData}
          wrapClassName=""
        />
      </div>
      <div className="common-bg-light mt-70">
        <div className="body-container">
          <RevealAnimation
            component={"h2"}
            className="title-header title-underline"
          >
            Recommended Reading
          </RevealAnimation>
          {megaviewData.data && megaviewData.data.length > 0 && (
            <div className="mt-50">
              <Megaview MegaviewData={megaviewData.data} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0 10px",
                }}
              >
                <Button
                  icon={
                    <img
                      className="effect-btn-svgicon"
                      src={RightArrow}
                      alt="Read More"
                    />
                  }
                  customClassName="info-view-resize-btn bg-white"
                  color="primary"
                  variant="outlined"
                  routepath="/white-paper"
                  label="Read More"
                  tip="Read More"
                  tooltip="no"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <Box BoxData={BoxData} />
      </div>
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
}

export default IndiaPage;
