import React from "react";
import "./style.scss";
import BannerIndiaImg from "../../assets/Images/India/banner/Start_Your_Journey.png";

import Button from "../Button";
import RightArrow from "../../assets/Icons/go.svg";
import { useContactModal } from "../Modal/contactModalContext";
import RevealAnimation from "../RevealAnimation";

function HeroSectionOverlay({ title, subtitle, description, bannerImg }) {
  const { setOpenModal } = useContactModal();

  return (
    <>
      <div className="hso-wrap">
        <div className="hso-container body-container">
          <RevealAnimation component={"h2"} className="title-header">
            {title}
          </RevealAnimation>
          <RevealAnimation component={"h3"} className="hso-subtitle">
            {subtitle}
          </RevealAnimation>
          <RevealAnimation component={"p"} className="hso-description">
            {description}
          </RevealAnimation>
        </div>
        <div className="hso-overlay">
          <img src={bannerImg} alt={title} />
        </div>
      </div>
      <div className="hsw-cta-wrap">
        <div className="hsw-cta-content">
          <RevealAnimation>
            Build Your World-Class Development Team Across India with Digiryte
          </RevealAnimation>
          <RevealAnimation>
            <Button
              icon={
                <img className="effect-btn-svgicon" src={RightArrow} alt="" />
              }
              customClassName="info-view-resize-btn bg-white"
              color="primary"
              variant="outlined"
              label="Start Your Journey"
              onClick={() => setOpenModal(true)}
              tip="Start Your Journey"
              tooltip="no"
              size="small"
            />
          </RevealAnimation>
        </div>
        <div className="hsw-cta-img">
          <img src={BannerIndiaImg} alt="" />
        </div>
      </div>
    </>
  );
}

export default HeroSectionOverlay;
