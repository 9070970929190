import React from "react";
import "./IconCard.scss";
import Button from "../Button";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import { useContactModal } from "../Modal/contactModalContext";
import RevealAnimation from "../RevealAnimation";

function IconCardSecondary({ imgUrl, title, description, isLast, btnText }) {
  const { setOpenModal } = useContactModal(); // Use context

  return (
    <RevealAnimation className="IconCardSecondary_wrap">
      {!isLast ? (
        <>
          <div className="IconCardSecondary_img">
            <img src={imgUrl} alt="" />
          </div>
          <div className="IconCardSecondary_content">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
          </div>
        </>
      ) : (
        <div className="IconCardSecondary_btn_wrap">
          <Button
            icon={
              <img
                className="effect-btn-svgicon"
                src={RightArrowWhite}
                alt={btnText}
              />
            }
            customClassName="info-view-resize-btn IconCardSecondary_btn"
            color="primary"
            variant="contained"
            label={btnText}
            onClick={() => setOpenModal(true)}
            tooltip="no"
          />
        </div>
      )}
    </RevealAnimation>
  );
}

export default IconCardSecondary;
